@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', serif;
  background-color: #F4F7FF ;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pricing-style{
  border: 4px solid #153A5B;
box-shadow: 10px 10px 0px #153A5B;
border-radius: 40px;
}
.my-shadow{
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);
}
.our-mission{
  border: 4px solid #153A5B;
box-shadow: 6px 6px 0px #153A5B;
border-radius: 20px;
}
.company-border{
border: 0.5px solid #D0D8DE;
border-radius: 10px;
}
.invoice-border{
border: 0.3px solid rgba(28, 121, 243, 0.4);
border-radius: 5px;
}

.dropdown{
  padding: 15px 20px;
gap: 10px;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.15), 0px 2px 14px rgba(0, 0, 0, 0.09);
border-radius: 10px;
}